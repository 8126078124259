import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/content.css"

class IndexPage extends React.Component {
  render() {
    const { data } = this.props
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout>
        <SEO title="Home" />
        <div className="content">
          <div className="post-list">
            {posts.map(({ node }) => {
              const title = node.frontmatter.title
              const thumnail = node.frontmatter.thumnail
              return (
                <div key={title} className="post-item">
                  <div className="post-stacked">
                    {null != thumnail ? <div className="post-thumbnail"><img alt={thumnail.name} src={thumnail.publicURL}></img></div> : ''}

                    <div className="post-title">{title}</div>
                    <div className="post-info">
                      Posted on {node.frontmatter.date}
                      </div>
                    <div className="post-content">
                      <div>{ node.excerpt }</div>
                      <Link className="readmore" to={node.frontmatter.path}> ReadMore++</Link>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div >
      </Layout >
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
      edges {
        node {
          excerpt
          frontmatter {
            date(formatString: "DD MMMM YYYY")
            title
            description
            path
            thumnail {
              id
              publicURL
              name
            }
          }
        }
      }
    }
  }
`
